// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://20.193.241.129:8000/', // For Local
  // apiUrl:'http://192.168.102.61:8080/'
  // apiUrl: 'https://uatwheelsdealsmw.tmf.co.in/'
  apiUrl  : 'https://wheelsdealsmw.tmf.co.in/'
};
