import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment'
import { AuthService } from './auth/authService';
import { RootService } from '../services/root.service'
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private httpClient: HttpClient;
  private state = `${environment.apiUrl}api/v1/get_states/`;
  private district = `${environment.apiUrl}api/v1/get_districts/`;
  private city = `${environment.apiUrl}api/v1/get_cities/`;
  private pincode = `${environment.apiUrl}api/v1/get_pincodes/`;
  private bid = `${environment.apiUrl}api/v1/add/bid/`;
  private emdblance = `${environment.apiUrl}api/v1/get_emd_balance/`;
  private MybidList = `${environment.apiUrl}api/v1/get_bids/`;
  private bidwinnings = `${environment.apiUrl}api/v1/get_bid_wins/`;
  private topbid = `${environment.apiUrl}api/v1/get_top_bids/`;
  private dashboard = `${environment.apiUrl}api/v1/dashboard/`;
  private homecount = `${environment.apiUrl}api/v1/home/`;
  private addEnquiry = `${environment.apiUrl}api/v1/add_enquiry/`
  private bankDetail = `${environment.apiUrl}api/v1/add/bank_details/`;
  private vehicleModel = `${environment.apiUrl}api/v1/admin/get_chassis_desc/`;
  private logout = `${environment.apiUrl}api/v1/logout/`;
  private loginAccept = `${environment.apiUrl}/api/v1/login_request_accept/`;

  public isDocumentUpload: BehaviorSubject<boolean>;


  constructor(private http: HttpClient, private auth: AuthService, private cookieService: CookieService, private handler: HttpBackend, private router: Router, private RootService: RootService) {
    this.httpClient = new HttpClient(handler);
    this.isDocumentUpload = new BehaviorSubject<boolean>(false);
     }

  bindHomeActivityCounter(): Observable<any> {
    return this.http.post(this.homecount, '').pipe(catchError(this.handleError))
  }
  BindState(): Observable<any> {
    return this.http.get(this.state).pipe(catchError(this.handleError))
  }

  BindDistrict(data): Observable<any> {
    return this.http.post(this.district, data).pipe(catchError(this.handleError))
  }

  BindCity(data): Observable<any> {
    return this.http.post(this.city, data).pipe(catchError(this.handleError));
  }

  BindPincode(data): Observable<any> {
    return this.http.post(this.pincode, data).pipe(catchError(this.handleError));
  }

  BindVehicleModel():Observable<any>{
    return this.http.post(this.vehicleModel, '').pipe(catchError(this.handleError));
  }

  addFinance(data):Observable<any>{
    return this.http.post(this.addEnquiry, data).pipe(catchError(this.handleError));
  }

  AddBid(data): Observable<any> {

    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.bid, data, HttpUploadOptions).pipe(catchError(this.handleError));
  }
  GetEMDBalance(): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.emdblance, '', HttpUploadOptions).pipe(catchError(this.handleError));
  }
  GetMybidlist(data): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.MybidList, data, HttpUploadOptions).pipe(catchError(this.handleError));
  }
  loginaccept(data): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.loginAccept, data, HttpUploadOptions).pipe(catchError(this.handleError));
  }
  addBankDetails(data): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.bankDetail, data, HttpUploadOptions).pipe(catchError(this.handleError));
  }



  bindBidWinningList(data): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.bidwinnings, data, HttpUploadOptions).pipe(catchError(this.handleError));
  }


  bindTopBidList(data): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.topbid, data, HttpUploadOptions).pipe(catchError(this.handleError));
  }

  bindDashboardDataCounter(): Observable<any> {
    const HttpUploadOptions = {
      headers: new HttpHeaders({
        'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
      })
    }
    return this.httpClient.post(this.dashboard, '', HttpUploadOptions).pipe(catchError(this.handleError));
  }

// logOut(){
//   const HttpUploadOptions = {
//     headers: new HttpHeaders({
//       'Content-Type': `application/json`, Authorization: `Bearer ${this.auth.getUserActualToken()}`,
//     })
//   }
//   return this.httpClient.post(this.logout, '', HttpUploadOptions).pipe(catchError(this.handleError));
// }

logOut() {
  const HttpUploadOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.auth.getUserActualToken()}`,
    }),
  };

  this.httpClient.post(this.logout, '', HttpUploadOptions).pipe(
    catchError((error) => {
      console.error('Logout API Error:', error);
      return throwError(error); // Ensure error is re-thrown if needed
    })
  ).subscribe(
    (response: any) => {
      this.Tempaccesstocken = localStorage.getItem('access_token');
    localStorage.clear()
    localStorage.removeItem('loginUserData')
    localStorage.removeItem('acctual_token')
    this.cookieService.removeAll();
    localStorage.setItem("isLoggedin", 'false');
    localStorage.setItem("access_token", this.Tempaccesstocken);
    this.Tempaccesstocken = '';
    this.router.navigate(['/page/home'])


    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    },
    (error) => {
      // Handle errors (optional)
      // this.toastr.error('Logout failed. Please try again.', 'Error');
    }
  );
}


  Tempaccesstocken: any

  UserLogout() {


    // this.logOut();
    this.Tempaccesstocken = localStorage.getItem('access_token');
    localStorage.clear()
    localStorage.removeItem('loginUserData')
    localStorage.removeItem('acctual_token')
    this.cookieService.removeAll();
    localStorage.setItem("isLoggedin", 'false');
    localStorage.setItem("access_token", this.Tempaccesstocken);
    this.Tempaccesstocken = '';
    this.router.navigate(['/page/home'])


    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

  }




  handleError(error: HttpErrorResponse) {
    return throwError(error)
  }
}
