import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment'
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  ExpireDateTime: any;
  private login = `${environment.apiUrl}api/v1/login/`;
  private getotp = `${environment.apiUrl}api/v1/send_otp/`;
  private existingUserRegistrationAPI = `${environment.apiUrl}api/v1/existing_customer/`;
  private registration = `${environment.apiUrl}api/v1/registration/`;
  private termscondition=`${environment.apiUrl}api/v1/user_agreement_accept/`
  constructor(private http: HttpClient) { }

  UserLogin(data): Observable<any> {
    return this.http.post(this.login, data).pipe(catchError(this.handlError));
  }
  GetOtp(data): Observable<any> {
    return this.http.post(this.getotp, data).pipe(catchError(this.handlError));
  }
  UserRegistration(data: FormData): Observable<any> {
    return this.http.post(this.registration, data).pipe(catchError(this.handlError));
  }
  uploadImageDocument(data: FormData) {
    return this.http.post<any>(this.registration, data).pipe(catchError(this.handlError))
  }

  datapost(data: FormData) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.http.post(this.registration, data, { headers: headers });
    //    return this.http.post(this.UploadDocumentUrl , data, this.httpOptions).pipe(catchError(this.handlError));
  }

  checkTermcondition(data):Observable<any>{
    return this.http.post(this.termscondition, data).pipe( catchError(this.handlError) );
  }



  // loggedIn() {
  //   this.ExpireDateTime = JSON.parse(localStorage.getItem('timer'));
  //   let date1 = new Date();
  //   let date2 = new Date(this.ExpireDateTime);
  //   if (date2 >= date1) {
  //     if (localStorage.getItem('acctual_token') && localStorage.getItem('acctual_token') != "null") {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     localStorage.clear();
  //     return false;
  //   }
  // }


  public getUserActualToken(): string {
    // if (this.loggedIn()) {
    //alert(localStorage.getItem('acctual_token'))
    // if (this.loggedIn()) {
    // }

    if (!localStorage.getItem('acctual_token')) {
      return localStorage.getItem('access_token');
    }
    else {
      return localStorage.getItem('acctual_token');
    }

    //   return localStorage.getItem('acctual_token');
    //}
  }
  existingUserRegistration(requestBody){
    return this.http.post(this.existingUserRegistrationAPI, requestBody).pipe(catchError(this.handlError));
  }
  handlError(error: HttpErrorResponse) {
    return throwError(error)
  }


}
